import React from 'react'
import RedLoader from '../../../../Profile/components/RedLoader/RedLoader'
import { viewAward } from '../../../bioGlobalFunctions'
import NoSearchAwards from '../NoSearchAwards/NoSearchAwards'
import AllAwardData from './AllAwardData'

const ViewAllDataContainer = ({ data, fetchMoreData, hasMore, userCode, navigate, bluetick, loading, searchLength, 
    dataLength, isSearch, searchHasMore, paginateLoader,searchText,isOpenSearch }) => {

    const allAwardsProps={data,fetchMoreData,hasMore,searchHasMore,paginateLoader,viewAward,userCode,navigate,bluetick }
  
    return (
        <>
            {loading ?
                <div className="loader-center">
                    <RedLoader />
                </div>
                : isSearch && searchLength === 0 ? <>
                    <NoSearchAwards isSearch={true} />
                </> : !loading && dataLength === 0 ? <>  <NoSearchAwards isSearch={false} /></> :
                  (( isOpenSearch&&searchText?.length!==0 && data?.length>0)  || (!isOpenSearch && data?.length>0 &&searchText?.length===0) )&& <AllAwardData
                    {...allAwardsProps}
                    >
                    </AllAwardData>
            }
        </>
    )
}

export default ViewAllDataContainer
