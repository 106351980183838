import { useNavigate } from "react-router-dom";
import React, { useRef } from "react";
import { navigateToLeadsWithState } from "../../../../../../globalFunctions";
import { useState } from "react";
import noImageLeads from '../../../../../../assets/images/leads-no-img-background.png'
import LeadsCardBottomInView from "./LeadsCardBottomInView";

const LeadsCards = ({ leadsData, userCode, isLive, productionUrl, setFormData,handleDragStart,handleDragEnd,handleDrag,MoveEventprops,slider }) => {
  const navigate = useNavigate();
  const navigateState = { isLive, productionUrl };
  const [locationLoader, setlocationLoader] = useState(true)
    
  return (
    <div className="leads-cards" id='profile-leads-list' onMouseDown={(e)=>handleDragStart(slider,MoveEventprops,e)} onMouseUp={(e)=>handleDragEnd(slider,MoveEventprops,e)} onMouseLeave={(e)=>handleDragEnd(slider,MoveEventprops,e)} onMouseMove={(e)=>handleDrag(slider,MoveEventprops,e)}>
      {leadsData.length !== 0 && leadsData?.map((item) => (
        <div
          onClick={(e) =>
            navigateToLeadsWithState(navigate, userCode, item?.leadId, "/my-bio/leads/responding-leads", navigateState, setFormData,e)}
          key={item?.leadId}
          className="card"
                    style={{
            backgroundImage: item?.backgroundImages?.length !== 0 ?
              `linear-gradient( rgba(0,0,0,0.2), rgba(0,0,0,0.7)), url(${item?.backgroundImages[0]})`
              : `url(${noImageLeads})`
          }} >
          <LeadsCardBottomInView locationLoader={locationLoader} item={item} setlocationLoader={setlocationLoader} />
        </div>
      ))}
    </div>
  );
};

export default LeadsCards;
