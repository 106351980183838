import React from 'react'
import BottomOptions from '../../../Cards/BottomOptions/BottomOptions'
import { handleOpenNewUrl } from '../../shareCardFunctions'

const ShareBottomOptions = ({ setOpen, setEnable, setOpenMail, setLocationEnable, setWebEnable, logo,
    cardUser, userDetail }) => {

    return (
        <>
            <BottomOptions
                setOpen={setOpen}
                setEnable={setEnable}
                setOpenMail={setOpenMail}
                setLocationEnable={setLocationEnable}
                setWebEnable={setWebEnable}
                isShareCard={true}
            />
            <div className="card-branding-new">
                <div className="logo-elred">
                    <img src={logo} alt="" />
                </div>
                <div className="main-card-profile" onClick={() => handleOpenNewUrl(userDetail)}>
                    <div className="profile-div" >
                        <img src={cardUser} alt="" />
                    </div>
                    <span className='shareTxt'>Profile</span>
                </div>
            </div>
        </>
    )
}

export default ShareBottomOptions
