import React from "react";
import "./bottomoptions.scss";
import call from "../../../assets/images/call.svg";
import globe from "../../../assets/images/globe.svg";
import location from "../../../assets/images/location.svg";
import mail from "../../../assets/images/mail.svg";
import S from "../../../assets/images/S.svg";
import star from "../../../assets/images/star.svg";

const BottomOptions = ({ setOpen, setEnable, setOpenMail, setLocationEnable, setWebEnable, isShareCard, setPop }) => {
  return (
    <div className={isShareCard?'bottom-options custom-width':'bottom-options'}>
      <div className="option-wrapper" onClick={()=>setOpen(true)}>
        <img src={S} alt="" />
      </div>
      <div className="option-wrapper" onClick={()=>setOpenMail(true)}>
        <img src={mail} alt="" />
      </div>
      <div className="option-wrapper" onClick={()=>setEnable(true)}>
        <img src={call} alt="" />
      </div>
      <div className="option-wrapper" onClick={()=>setLocationEnable(true)}>
        <img src={location} alt="" />
      </div>
      <div className="option-wrapper" onClick={()=>setWebEnable(true)}>
        <img src={globe} alt="" />
      </div>
    </div>
  );
};

export default BottomOptions;
