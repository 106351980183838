import {UserDetails, Badges, SaveButton, ShareBottomOptions, caps, BackgroundFilter} from './ImportsShareCardContainer'

const ShareCardContainer = ({ userDetail, cardDetail, data, setOpen, setEnable, setOpenMail,
    setLocationEnable, setWebEnable, logo, cardUser }) => {

    const shareBottomOptionsProps = { setOpen, setEnable, setOpenMail, setLocationEnable, setWebEnable,
    logo, cardUser, userDetail }

    const {firstname, lastname} = userDetail;

    return (
    <>
        <div className="username">
            {caps(userDetail?.firstname) + `'s Personal Card`}
        </div>
        <div className="card-div">
            <div className="card-container"
                style={{ backgroundImage: cardDetail?.cardDesignType !== "typeA" &&
                            cardDetail?.cardDesignType !== "typeB"
                            ? cardDetail?.cardShortBgURL !== ""
                                ? `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url(${cardDetail?.cardShortBgURL})`
                                : `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url(${cardDetail?.customImageCardDesignInfo?.profileBannerImageURL})`
                            : cardDetail?.cardShortBgURL !== ""
                                ? `url(${cardDetail?.cardShortBgURL})`
                                : `url(${cardDetail?.customImageCardDesignInfo?.profileBannerImageURL})`,
                          backgroundPosition: cardDetail?.cardShortBgURL == "" ? "center" : null,                         
                }} >            
            <div className="bg-filter-sharecard" >
                <UserDetails userDetail={userDetail} firstname={firstname} lastname={lastname} />
                <div className="badges-div">
                    <Badges data={userDetail?.awards} count={data?.userSpecificAwardsCount} />
                </div>
            </div>
                <ShareBottomOptions {...shareBottomOptionsProps}/>
            </div>
            {cardDetail?.customImageCardDesignInfo?.colorFilter?.length !== 0 ? 
                <BackgroundFilter filterValues={cardDetail?.customImageCardDesignInfo?.colorFilter?.toString()} /> : null}
        </div>
        <SaveButton userDetail={userDetail} cardButton="shareCard"/>
    </>
    )
}

export default ShareCardContainer
