import React from 'react'
import { Spinner } from 'react-bootstrap'

const ChatInputOverlay = ({sendOverlay, value, sendLoader, sendDisable, setSendLoader, 
    sendMessageFromInput, sendMessageFromInputProps, send}) => {

  return (
    <>
      {sendOverlay ? (
            <Spinner animation="border" variant="danger" size="sm" style={{margin:'16px'}}/>
          ) : value?.trim()?.length == 0 ? (
            <>
            <Spinner animation="border" variant="dark" size="sm" className={sendLoader ? 'show-img-loader needs-top-margin-loader' : 'hide-img-loader'} style={{position:"absolute",left:"87%"}}/>
            <div className="send_btn_click_disabled">
            <img src={sendDisable} alt="" className="disable_btn" onLoad={()=>setSendLoader(false)}/>
            </div>
            </>
          ) : (
            <div onClick={() => sendMessageFromInput(sendMessageFromInputProps) } className="send_btn_click">
              <img src={send} className="enable_btn" alt="" />
            </div>
          )}
    </>
  )
}

export default ChatInputOverlay
