import React, { useEffect, useState } from "react";
import share from '../../../../assets/images/share-arrow.svg'
import avatar from '../../../../assets/images/avatar.png'
import { handleShareCard } from "../../shareCardFunctions";
import DesignationListProfile from "../../../components/DesignationListProfile/DesignationListProfile";
import { calcTextLength, caps, makeFirstCapital } from "../../../../globalFunctions";
import blueTick from "../../../../assets/images/blue_tick.svg";

const UserDetails = ({ userDetail, firstname, lastname }) => {

  let city = userDetail?.location?.city?.length > 0 ? userDetail?.location?.city + ", " : "";
  const location = city + userDetail?.location?.country;

  return (
    <>
      <div className="userdetails-sharing" >
        <div className="share-icon" onClick={() => handleShareCard(userDetail)}>
          <img src={share} alt="" />
        </div>
        <span className="title" onClick={() => handleShareCard(userDetail)}>Share</span>
      </div>
      <div className="user-image">
        <img src={userDetail?.dpURL==""?avatar: userDetail?.dpURL} alt="" />
      </div>
      {userDetail?.aadhaarVerifiedStatus && <img src={blueTick} alt="" className="verified-user-share-card-icon" />}
      <div className="user-name">{calcTextLength(12, userDetail?.firstname,'name')}</div>
      <div className="user-surname">{calcTextLength(20, userDetail?.lastname, 'name')}</div>
      <div className="professions" style={{ fontWeight: '600' }}>
          <DesignationListProfile designation={userDetail?.title} maxWidth={180}/>
      </div>
      <div className="locations" style={{ fontWeight: '600' }}>
        {location?.length <= 27 ? location : location?.slice(0, 27) + '...'}
      </div>
    </>
  );
};

export default UserDetails;
