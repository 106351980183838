import React from 'react'
import noResume from '../../assets/images/noresume.png'
import noResumePng from '../../assets/images/webtest.webp'

const Test = () => {
  return (
    <div>
      <img src={noResume} alt="" />
          <img src={noResumePng} alt="" />
    </div>
  )
}

export default Test
