import React from 'react'
import TopHeader from '../components/TopHeader/TopHeader'
import UpperProfileData from './UpperProfileData/UpperProfileData'
import LowerProfileData from './LowerProfileData/LowerProfileData'
import BackgroundFilter from '../components/BackgroundFilter/BackgroundFilter'

const ProfileWithData = ({ data, rgba, tint, designTypeC, secondaryColor, baseColor, primaryColor, textColor,
    superSkillsIcon, isLive, productionUrl, miniCardData, wholeData, ratingsData, ratingsWholeData, hasMore, fetchMorePage,meetSearchMore,ethicalSearchMore,
    needsData, needsAvailable, metWholeData, virtuallyMetData, isMore, userCode, fetchNextPage,meetfetchLoader,loader, closeMeetpopUp,closeEthicalPopup,
    deBoundeEthical,deBoundeMeet, meetLoader, ethicalloader, setLoading, meetSetLoading,ratingsDataSearch,virtuallyMetDataSearch,  isSearchEthical,isSearchMeet,
    leadsData, leadsAvailable ,leadsLoading,getCOuntofLeads}) => {

    const upperProfileDataProps = {
        data, miniCardData, baseColor, secondaryColor, tint, wholeData, rgba, userCode,
        leadsData, leadsAvailable, isLive, productionUrl,leadsLoading,getCOuntofLeads}

    const lowerProfileDataProps = {
        rgba, ratingsWholeData, metWholeData, ratingsData, virtuallyMetData, hasMore, isMore,
        fetchNextPage, fetchMorePage, isLive, productionUrl, userCode, superSkillsIcon, needsAvailable, needsData, 
         deBoundeEthical,deBoundeMeet, meetLoader, ethicalloader, setLoading, meetSetLoading,ratingsDataSearch,virtuallyMetDataSearch,
         isSearchEthical,isSearchMeet,meetfetchLoader,loader, closeMeetpopUp,closeEthicalPopup,meetSearchMore,ethicalSearchMore }

  return (
    <> <div className="parent-profile"
        style={{
          backgroundImage: tint ? `linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),url(${data?.result?.[0]?.profileDesignInfo?.profileBannerImageURL})` :
          `url(${data?.result?.[0]?.profileDesignInfo?.profileBannerImageURL})`,
          height: designTypeC == "ptypeC" ? "auto" : "",
          backgroundSize: designTypeC == "ptypeC" ? "contain" : "cover",
           backgroundPosition:designTypeC  == "ptypeC" ? "100% 0%" :'50% 0%',
          backgroundColor: designTypeC == "ptypeC" ? `#${secondaryColor}` : "",
          height : designTypeC === 'ptypeC' ? '' : '100vh',
          overflow: designTypeC === 'ptypeC' ? '' : 'auto',
        }}
      >
        <div className="profile" style={{ background: `#${primaryColor}` }}>
          <TopHeader color={`#${textColor}`} />
        </div>
        {/* <div className="profile-body bg-filter-profile-with-data" style={{ background: tint ? "rgba(0,0,0,0.4)" : null }}> */}
        <div className="profile-body bg-filter-profile-with-data">
          <UpperProfileData {...upperProfileDataProps} />
          <LowerProfileData {...lowerProfileDataProps} />
        </div>
        {data?.result[0]?.customImageCardDesignInfo?.colorFilter.length !==
        0 ? (
          <BackgroundFilter
            filterValues={data?.result[0]?.customImageCardDesignInfo?.colorFilter?.toString()}
          />
        ) : null}
      </div>
    </>
  );
};

export default ProfileWithData
