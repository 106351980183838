import React from "react";
import "./badges.scss";

const Badges = ({ data, count }) => {

  return (
    <div className="badges">

      {count <= 4 ? (
        <>
          {data?.map((item, id) => (
            <div className="badge">
              <img src={item.awardIconURL} alt="" />
            </div>
          ))}
        </>
      ) : (
        <>
          {data?.slice(0, 3).map((item, id) => (
            <div className="badge" key={id}>
              <img src={item.awardIconURL} alt="" />
            </div>
          ))}
          <div className="badge total-badge">
            +{count - (data?.length - 1)}
          </div>
        </>
      )}
    </div>
  );
};

export default Badges;
