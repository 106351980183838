import SuperSkill1 from '../../assets/images/superskill_1.png'
import SuperSkill2 from '../../assets/images/superskill_2.png'
import SuperSkill3 from '../../assets/images/superskill_3.png'
import SuperSkill4 from '../../assets/images/superskill_4.png'

export const imagesSkill = [ 
    { id: 1, img: SuperSkill1}, 
    { id: 2, img: SuperSkill2}, 
    { id: 3, img: SuperSkill3}, 
    { id: 4, img: SuperSkill4}
  ]
