import React, { createContext, useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "./App.scss";
import Routing from "./Routing";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import { getBrowserType, isProductionEnvironment } from "./globalFunctions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UnsupportedBrowser from "./TashafsModule/components/UnsupportedBrowser/UnsupportedBrowser";

export const GlobalData = createContext();

function App() {
  const isLive = isProductionEnvironment();
  const productionUrl = "https://demo.elred.io";

  const [formData, setFormData] = useState({
    message: "",
    f_name: '',
    l_name: '',
    phone_number: '',
    website1: '',
    website2: '',
    contact_type: 'whatsapp'
  });
  const [isOffline, setisOffline] =  useState(false)
  const BROWSER_TYPE = getBrowserType();

  window.addEventListener("offline", (event) => {
    // console.log("The network connection has been lost.");
    setisOffline(true)
  });

  window.addEventListener("online", (event) => {
    // console.log("You are now connected to the network.");
    setisOffline(false)
  });

  console.log(BROWSER_TYPE,'BROWSER TYPE')
  if(BROWSER_TYPE!=='Google Chrome'){
    return <UnsupportedBrowser/>
  }
  return (
    <Container className="main-container">
      <GlobalData.Provider value={{ formData, setFormData  }}>
        <Routing isLive={isLive} productionUrl={productionUrl} isOffline={isOffline} setisOffline={setisOffline}/>
        <ToastContainer position="top-center" />
      </GlobalData.Provider>
    </Container>
  );
}

export default App;
