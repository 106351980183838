import React from "react";
import "./noAwards.scss";
import norating from "../../../../../assets/images/no-testimonials.png";
import noAwards from "../../../../../assets/images/noawards.png"
function NoSearchAwards({isSearch}) {
  return (
    <div className="container-wrapper">
      <div className="centered-div ">
        <div className="d-flex align-items-center justify-content-center flex-column">
         {isSearch? <>
          <img alt="" src={norating} />
          <p className="no-ratings mt-4">No search result found</p>
         </>:
          <>
           <img className="no-Awards" alt="" src={noAwards} />
          <p className="no-ratings mt-4">No awards & honours added yet</p>
          </>
          }
        </div>
      </div>
    </div>
  );
}

export default NoSearchAwards;
